import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="link">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="section">
                    Buďte krásná
                  </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="top">
                <h2>Péče o nohy</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/budte-krasna/">Buďte krásná</a> <span>&gt;</span>
                  Péče o nohy
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/budte-krasna/sucha-pokozka/" className="link">
                Suchá pokožka
              </a>
              <a href="/budte-krasna/pece-o-plet/" className="link">
                Péče o pleť
              </a>
              <a href="/budte-krasna/pece-o-rty/" className="link">
                Péče o rty
              </a>
              <a href="/budte-krasna/pece-o-ruce/" className="link">
                Péče o ruce
              </a>
              <a href="/budte-krasna/pece-o-nohy/" className="current">
                Péče o nohy
              </a>
              <a href="/budte-krasna/pece-o-tlo/" className="link">
                Péče o tělo
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first"></section>
              <section>
                <div className="bg stopy1">
                  <div className="text left">
                    <p>
                      <br />
                      <br />
                      <br />
                    </p>
                    <h2>Mytí nohou</h2>
                    <p>
                      Mytí nohou není jen základním požadavkem hygieny, ale také
                      – zejména v létě – efektivní ochranou proti pocení a
                      pocitu těžkých nohou. Chodidla by měla být myta vlažnou
                      vodou a pak důkladně osušena ručníkem. Dbejte na to,
                      abyste chodidla, především mezi prsty, nedrhla ručníkem.
                      To může totiž způsobit poškození, které může vést k
                      výskytu hub a bakterií. Snažte se ošetřovat svá chodidla
                      alespoň jednou týdně koupelí se speciální kosmetickou solí
                      a následně aplikujte peeling.
                    </p>
                  </div>
                </div>
              </section>
              <section>
                <div className="bg stopy2">
                  <div className="text right">
                    <h2>Peeling na nohy</h2>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      Peeling na nohy je stejně důležitý jako peeling na tělo.
                      Účinně zlepšuje cirkulaci a umožňuje odstranit mrtvé kožní
                      buňky a mozoly. Je-li prováděn systematicky, vyhlazuje
                      hrubou kůži a výrazně změkčuje Vaše chodidla, čímž je
                      připravuje pro řádnou hydrataci a promaštění. Dbejte na
                      systematické odstraňování keratinizované pokožky, můžete
                      tak výrazně zabránit vzniku zatvrdlin.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="bg stopy3">
                  <div className="text left">
                    <h2>Hydratace a promašťování chodidel</h2>
                    <p>
                      Hydratace a promašťování chodidel je primární péče a jejím
                      úkolem je účinně vyživovat a regenerovat epidermis.
                      Budete-li dbát na každodenní hydrataci chodidel, vyhnete
                      se vzniku zatvrdlin a pigmentaci. NEUTROGENA
                      <sup>®&nbsp;</sup>Norská receptura Výživný krém na nohy
                      nabízí 24 hodinovou hydrataci. Vyzkoušejte také jiné
                      výrobky s Norskou recepturou pro péči o nohy. Zklidní
                      popraskanou pokožku chodidel, pomáhají regenerovat s
                      viditelnými výsledky od 1. dne.
                    </p>
                  </div>
                </div>
              </section>
              <div id="fb_74"></div>
             </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/neutrogena/o-nas/">
                  O ZNAČCE NEUTROGENA<sup>®</sup>
                </a>
                <span>|</span>
                <a href="/neutrogena/norska-receptura/">Norská receptura</a>
                <span>|</span>
                <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
                <span>|</span>
                <a href="/produkty/">
                  Zobrazit produkty NEUTROGENA<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
          <Footer></Footer>
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
